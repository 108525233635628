export const usePanelsStore = defineStore('panels', () => {
  const panels = ref(new Map<string, { props?: Record<any, any>; onClose?: Function }>())
  const activePanel = ref<string | null>(null)

  const closePanel = (panel: string): void => {
    if (activePanel.value === panel) {
      // Execute the onClose callback if it exists
      panels.value.get(panel)?.onClose?.()

      panels.value.delete(panel)

      activePanel.value = Array.from(panels.value.keys()).at(-1) ?? null

      setTimeout(() => {
        if (activePanel.value === 'EmptyPanel') {
          panels.value.clear()
        }
      }, 700)
    }
  }

  const openPanel = (panel: string, props?: Record<any, any>, onClose?: Function): void => {
    if (panels.value.size === 0) {
      panels.value.set('EmptyPanel', {})
    }

    activePanel.value = panel

    panels.value.set(panel, { props: props ?? {}, onClose })
  }

  const clearPanels = () => {
    panels.value.clear()
  }

  return { panels, activePanel, clearPanels, closePanel, openPanel }
})

// HMR support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePanelsStore, import.meta.hot))
}
